$blue: #3d55cc;
$orange: #ff6b00;
$dark: #093057;
$gray: #516679;
$primary: #3d55cc;
$secondary: #ef476f;

// bootsrap colors
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray-dark: #343a40;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
