@import "assets/scss/variables";
@import "../node_modules/bootstrap/scss/bootstrap";

body {
  color: $dark;
}
.pt-65px {
  padding-top: 65px;
}

.color-inherit {
  color: inherit;
}

.fs-10 {
  font-size: 10px;
}

.border-1px-solid {
  border: 1px solid;
}

.height-300 {
  height: 300px;
}

.height-200 {
  height: 200px;
}

.size-24px {
  height: 24px;
  width: 24px;
}

.z-index-top {
  z-index: top;
}

.tooltip {
  opacity: 1 !important;
  .tooltip-inner {
    @media (min-width: 760px) {
      max-width: 300px;
    }
    background: white;
    color: $dark;
    box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }
  .arrow::before {
    // border-color: white;
    border-bottom-color: white;
  }
}

.bg-opacity {
  background: #e6e6ff;
}

//on mobile switch cards order
@media (max-width: 760px){
  .order-card-1{
    order: 1;
  }
  .order-card-2{
    order: 2;
  }
}
